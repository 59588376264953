<template>
  <span>{{ prefix }}{{ newValueFormatted }} {{ suffix }}</span>
</template>

<script>
import numeral from "numeral";

export default {
  name: "GrowingNumber",
  props: {
    prefix: { type: String, default: null },
    suffix: { type: String, default: null },
    value: { type: Number, default: 0 },
    duration: { type: Number, default: 500 },
    hideNum: { type: Boolean, default: false },
    lookup: { type: Array, default: null }
  },
  data() {
    return {
      newValue: 0,
      step: 0
    };
  },
  computed: {
    newValueFormatted() {
      // prettier-ignore
      return this.hideNum
        ? ""
        : this.lookup
          ? this.lookup[this.newValue]
          : this.newValue < 1000
            ? this.newValue
            : numeral(this.newValue).format("0,0");
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue > oldValue) {
        this.growInit(newValue - oldValue);
      } else {
        this.shrinkInit(oldValue - newValue);
      }
    }
  },
  mounted() {
    this.growInit(this.value);
  },
  methods: {
    growInit(v) {
      const m = v / (this.duration / 25);
      this.grow(m);
    },
    shrinkInit(v) {
      const m = v / (this.duration / 25);
      this.shrink(m);
    },
    grow(m) {
      const v = Math.ceil(this.newValue + m);

      if (v > this.value) {
        this.newValue = this.value;
        return false;
      }

      this.newValue = v;
      setTimeout(() => {
        this.grow(m);
      }, 25);
    },
    shrink(m) {
      const v = Math.floor(this.newValue - m);

      if (v < this.value) {
        this.newValue = this.value;
        return false;
      }

      this.newValue = v;
      setTimeout(() => {
        this.shrink(m);
      }, 25);
    }
  }
};
</script>
